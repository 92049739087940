import(/* webpackMode: "eager", webpackExports: ["ClientOnly"] */ "/app/apps/nextjs/src/components/client-only.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Provider"] */ "/app/apps/nextjs/src/components/command/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/app/apps/nextjs/src/components/cookie-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationBlockerProvider"] */ "/app/apps/nextjs/src/components/navigation-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Polyfill"] */ "/app/apps/nextjs/src/components/polyfill.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/apps/nextjs/src/components/providers/theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaskSettingsProvider"] */ "/app/apps/nextjs/src/components/task-settings-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Translation"] */ "/app/apps/nextjs/src/components/translations/component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationProvider"] */ "/app/apps/nextjs/src/components/translations/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/apps/nextjs/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubjectsProvider"] */ "/app/apps/nextjs/src/lib/subjects/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-dom@18_xoowf7keoldgho3im7h4hxo6bm/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-dom@18_xoowf7keoldgho3im7h4hxo6bm/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-dom@18_xoowf7keoldgho3im7h4hxo6bm/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/KGFirstTimeInForever.ttf\",\"variable\":\"--font-heading\"}],\"variableName\":\"fontHeading\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-dom@18_xoowf7keoldgho3im7h4hxo6bm/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/CalSans-SemiBold.woff2\",\"variable\":\"--font-logo\"}],\"variableName\":\"fontLogo\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-dom@18_xoowf7keoldgho3im7h4hxo6bm/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Redacted\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-redacted\",\"weight\":\"400\"}],\"variableName\":\"fontRedacted\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-dom@18_xoowf7keoldgho3im7h4hxo6bm/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Redacted_Script\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-redacted-script\",\"weight\":\"400\"}],\"variableName\":\"fontRedactedScript\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/.pnpm/nextjs-toploader@1.6.6_next@14.2.11_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+t_aylqpcjp6ab7sfg3b3vkv46lai/node_modules/nextjs-toploader/dist/index.js");
